<template>
  <v-dialog justify="center" :value="value" persistent max-width="500">
    <v-card color="fill" class="pa-4">
      <v-icon color="primary" class="mr-3" x-large>mdi-help-rhombus-outline</v-icon>
      <v-card-text class="py-6 font-weight-bold black--text text-center">
        This process will create or overwrite existing files on the FTP server for {{selectedAd.ad_name}}.
      </v-card-text>
      <v-card-actions>
        <v-col>
          <v-row align="center">
              <v-spacer/>
                <v-checkbox v-model="hosting" label="Hosting"></v-checkbox>
                <v-menu
                  v-if="selectedAd.hosting_file_generated"
                  open-on-hover
                  offset-y
                  top
                  :close-on-content-click="false">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="pa-0 ma-0"
                      v-on="on"
                      small
                      icon>
                      <v-icon class="ma-0 pa-0" color="success">mdi-check-circle-outline</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-list dense>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle>Last Generated on</v-list-item-subtitle>
                          <v-list-item-title v-if="selectedAd.hosting_file_generated_on">
                            {{ $dateConfig.formatDateTime(selectedAd.hosting_file_generated_on) }}
                          </v-list-item-title>
                          <v-list-item-title v-else>
                            -
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
              <v-spacer/>
              <v-checkbox v-model="pangea" label="Pangea"></v-checkbox>
                  <v-menu
                  v-if="selectedAd.pangea_file_generated"
                  open-on-hover
                  offset-y
                  top
                  :close-on-content-click="false">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="pa-0 ma-0"
                      v-on="on"
                      small
                      icon>
                      <v-icon class="ma-0 pa-0" color="success">mdi-check-circle-outline</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-list dense>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle>Last Generated on</v-list-item-subtitle>
                          <v-list-item-title v-if="selectedAd.pangea_file_generated_on">
                            {{ $dateConfig.formatDateTime(selectedAd.pangea_file_generated_on) }}
                          </v-list-item-title>
                          <v-list-item-title v-else>
                            -
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
              <v-spacer/>
              <v-checkbox v-model="connect" label="Connect"></v-checkbox>
                <v-menu
                v-if="selectedAd.connect_file_generated"
                open-on-hover
                offset-y
                top
                :close-on-content-click="false">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="pa-0 ma-0"
                      v-on="on"
                      small
                      icon>
                      <v-icon class="ma-0 pa-0" color="success">mdi-check-circle-outline</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-list dense>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle>Last Generated on</v-list-item-subtitle>
                          <v-list-item-title v-if="selectedAd.connect_file_generated_on">
                            {{ $dateConfig.formatDateTime(selectedAd.connect_file_generated_on) }}
                          </v-list-item-title>
                          <v-list-item-title v-else>
                            -
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
              <v-spacer/>
          </v-row>
          <v-row>
            <v-spacer/>
              <v-btn text @click.stop="$emit('update:value', false)">
                <v-icon left>mdi-close</v-icon>No
              </v-btn>
              <v-btn text color="green" class="ml-2" :disabled="!hosting && !pangea && !connect" @click="openConfirmation">
                <v-icon left>mdi-checkbox-marked-circle-outline</v-icon>Yes
              </v-btn>
          </v-row>
        </v-col>
      </v-card-actions>
    </v-card>
    <Confirmation v-model="showConfirmation" v-on:close-dialog="closeDialog"
    v-bind:selectedAd="selectedAd" v-bind:hosting="hosting" v-bind:pangea="pangea" v-bind:connect="connect" v-bind:anyReruns="anyReruns"/>
  </v-dialog>
  
</template>


<script>
import { notification } from '@/mixins/notification'
import Confirmation from '@/components/ads/adplan-file-confirmation'

export default {
  name: 'GenAdPlanFiles',

  mixins: [notification],

  data() {
    return {
      hosting: !this.selectedAd.hosting_file_generated,
      pangea: !this.selectedAd.pangea_file_generated,
      connect: !this.selectedAd.connect_file_generated,
      showConfirmation: false
    }
  },

  components: {
    Confirmation
  },

  methods: {
    openConfirmation(){
      this.showConfirmation=true
    },
    closeDialog(){
            this.$emit('closeAndRefresh')
            this.showConfirmation=false
      }
  },

  computed: {
    hostingLabel () {
      if (this.selectedAd.hosting_file_generated) {
        return "Hosting (Rerun)"
      }
      else {
        return "Hosting"
      }
    },
    pangeaLabel () {
      if (this.selectedAd.pangea_file_generated) {
        return "Pangea (Rerun)"
      }
      else {
        return "Pangea"
      }
    },
    connectLabel () {
      if (this.selectedAd.connect_file_generated) {
        return "Connect (Rerun)"
      }
      else {
        return "Connect"
      }
    },
    anyReruns () {
      return ((this.selectedAd.hosting_file_generated && this.hosting) || (this.selectedAd.pangea_file_generated && this.pangea)) || (this.selectedAd.connect_file_generated && this.connect)
    }
  },

  props: ['value', 'selectedAd']
}
</script>
