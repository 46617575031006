<template>
  <v-dialog justify="center" :value="value" persistent max-width="500">
    <v-card color="fill" class="pa-4">
    <v-icon color="primary" class="mr-3" x-large>mdi-alert-circle-outline</v-icon>
    <v-card-text class="py-6 font-weight-bold black--text text-center">
    <span v-if="hosting && pangea && connect" >
    You are about to create new Hosting, Pangea, and Connect files for the current Ad. These files will be posted to the production FTP site for processing.
    </span>
    <span v-else-if="hosting && pangea && !connect">
    You are about to create new Hosting and Pangea files for the current Ad. These files will be posted to the production FTP site for processing.
    </span>
    <span v-else-if="hosting && !pangea && connect">
    You are about to create new Hosting and Connect files for the current Ad. These files will be posted to the production FTP site for processing.
    </span>
    <span v-else-if="!hosting && pangea && connect">
    You are about to create new Pangea and Connect files for the current Ad. These files will be posted to the production FTP site for processing.
    </span>
    <span v-else-if="hosting && !pangea && !connect">
    You are about to create a new Hosting file for the current Ad. These files will be posted to the production FTP site for processing.
    </span>
    <span v-else-if="!hosting && pangea && !connect">
    You are about to create a new Pangea file for the current Ad. These files will be posted to the production FTP site for processing.
    </span>
    <span v-else-if="!hosting && !pangea && connect">
    You are about to create a new Connect file for the current Ad. These files will be posted to the production FTP site for processing.
    </span>
    </v-card-text>
    <div v-if="anyReruns">
      <v-card-text class="mt-5">
      {{warningText}}
      </v-card-text>
    </div>
      <v-card-actions>
        <v-spacer/>
            <v-btn text @click="$emit('close-dialog')">
            <v-icon left>mdi-close</v-icon>No
            </v-btn>
            <v-btn text color="green" class="ml-2" @click="generateFiles" :loading="loading">
            <v-icon left>mdi-checkbox-marked-circle-outline</v-icon>Yes
            </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  
</template>


<script>
import { notification } from '@/mixins/notification'


export default {
  name: 'Confirmation',

  mixins: [notification],

  props: ['value', 'selectedAd', 'hosting', 'pangea', 'connect', 'anyReruns'],

  data () {
    return {
    loading: false,
    }
  },
  
  methods: {
    async generateFiles () {
        this.loading = true
        const AdData = {
            "id": this.selectedAd.id, 
            "ad_party_id": this.selectedAd.ad_party_id,
            "ad_start_date": this.selectedAd.ad_start_date,
            "ad_end_date": this.selectedAd.ad_end_date,
            "hosting": `${this.hosting}`,
            "pangea": `${this.pangea}`,
            "connect": `${this.connect}`
                        }
        // console.log(AdData)
        try {
          await this.$Ads.generateAdPlanFiles(AdData)
          this.$store.dispatch('setSnackbar', { status: 'success', text: `${this.selectedAd.ad_name} Successfully Submitted` })
          this.$emit('close-dialog')
        }
        catch (err) {
          this.$store.dispatch('setSnackbar', { status: 'error', text: `Submission Failed due to ${err?.response?.data?.message || err.message}` })
        }
        finally{
          this.loading = false
        }
    }
  },
  computed: {
    warningText () {
      var warning = "WARNING: "
      var arr = []
      if (this.selectedAd.hosting_file_generated && this.hosting){
        arr.push("Hosting")
      }
      if (this.selectedAd.pangea_file_generated && this.pangea){
        arr.push("Pangea")
      }
      if (this.selectedAd.connect_file_generated && this.connect){
        arr.push("Connect")
      }

      var files = arr.join(", ")

      warning = warning + files + " file(s) already generated. A new run will overwrite old files."

      return warning
    }
  }
}
</script>
